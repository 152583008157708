import equal from "fast-deep-equal";
import React, {Component} from "react";
import GoogleBtn from "./GoogleBtn";
import OAuthEcobee from "./OAuthEcobee";
import OAuthNest from "./OAuthNest";
import DeviceList from "./DeviceList";
import config from '../config.js';
import {ReactSession} from "react-client-session";
import {fetch_with_token} from "./util";
import LocationForm from "./LocationForm";
import DeviceInfo from "./DeviceInfo";

export class DeviceArea extends Component{
    constructor(props){
        super(props)
        this.state = {
            login: {
                is_authenticated: false,
            user_name: ''},
            user_state: {
                credentials: {}},
            user_state_loaded: false,

            tick_starting_interval: 1000,
            tick_current_interval: 1000, // milliseconds
            tick_max_interval: 100000,
            tick_increase: 1.25, // multiplier
        }

        this.set_global_state = this.set_global_state.bind(this);
        this.load_backend_state = this.load_backend_state.bind(this);
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);

    }

    componentWillMount() {
        window.kwdebug = { // debug only -- remove before release
            ReactSession: ReactSession,
            fetch_with_token: fetch_with_token
        }
    }


    set_global_state(key, data) {
        this.setState({[key]: data});
    }



    login(data){
        this.setState({'login': data})
        if(!this.state.tick){
            this.setState({'tick': setTimeout(this.tick, this.state.tick_current_interval)});  // TODO this should back off over time
        }
    }

    logout(){
        this.setState({'login': {}})
        if(this.state.tick){
            clearTimeout(this.state.tick);
            this.setState({tick: null, tick_current_interval: this.state.tick_starting_interva});
        }
    }


    tick = () => {
        this.load_backend_state();
        this.state.tick_current_interval *= this.state.tick_increase;
        this.setState({'tick': setTimeout(this.tick, this.state.tick_current_interval)});
        console.log('next tick in ' + this.state.tick_current_interval + ' ms')
    }

    load_backend_state() {
        const uri = `${config.urls.user_state}/?user_id=${this.state.login.user_id}&action=user_state`
        fetch_with_token(uri)
            .then(response => response.json())
            .then(jsondata => {
                // TODO compare, only set state if updated
                    this.setState({
                        user_state: jsondata,
                        user_state_loaded: true,
                    })
                }
            )}


    render(){
        return    (    <div>
            <GoogleBtn login={this.state.login} user_state={this.state.user_state} login_func={this.login} logout_func={this.logout}
            load_backend_state={this.load_backend_state}/>
                {this.state.login.is_authenticated && this.state.user_state_loaded &&
                <div>
                    <OAuthEcobee user_state={this.state.user_state} login={this.state.login}/>
                    <OAuthNest login={this.state.login} user_state={this.state.user_state}
                               load_backend_state={this.load_backend_state}/>
                    <DeviceList user_state={this.state.user_state}/>
                    <LocationForm/>
                    {this.state.user_state.thermostats &&
                    this.state.user_state.thermostats.map(thermo_details =>
                    {return <DeviceInfo thermostat_details={thermo_details}></DeviceInfo>})}
                </div>

                }
            </div>
    )
    }
};