import React, { Component } from 'react';
import {GoogleLogin, GoogleLogout} from 'react-google-login';
import OAuthEcobee from './OAuthEcobee';
import 'bootstrap/dist/css/bootstrap.min.css';
import DeviceList from "./DeviceList";
import OAuthNest from "./OAuthNest";
import {fetch_with_token, log} from "./util";
import config from "../config";
import {ReactSession} from "react-client-session";

const CLIENT_ID = '712794027289-dke7sjgaocdk24da3q40pe93pus2b5nr.apps.googleusercontent.com';

class GoogleBtn extends Component {
    constructor(props) {
     super(props);

     this.state = {
       isLogined: !!ReactSession.get('token'),
       accessToken: '',
       tokenId: '',
       googleId: '',
       tokenObj: null,
       message: '',
     };
 
     this.login = this.login.bind(this);
     this.handleLoginFailure = this.handleLoginFailure.bind(this);
     this.logout = this.logout.bind(this);
     this.handleLogoutFailure = this.handleLogoutFailure.bind(this);
     this.complete_oauth_login = this.complete_oauth_login.bind(this);

     const token = ReactSession.get('token');
     if(token) {
         // need to store state after login
         const propagated_state = {
             is_authenticated:  true,
             user_name: ReactSession.get('user_name'),
             user_id: ReactSession.get('user_id')
         }
         this.props.login_func(propagated_state);
         this.props.load_backend_state();
     }
   }
 
   async login (response) {
     if(response.accessToken){
       log(response);
       this.complete_oauth_login(response);

       let userGreeting = response['profileObj']['givenName'];


       
       this.setState(state => ({
         isLogined: true,
         accessToken: response.accessToken, // never actually used
         tokenId: response.tokenId,
         googleId: response.googleId,
         tokenObj: response.tokenObj,
         message: userGreeting,
         // codeUrl: authUrl
       }));
         const propagated_state = {
             is_authenticated: this.state.isLogined,
             user_name: userGreeting,
             user_id: this.state.googleId
         }
         ReactSession.set('user_name', userGreeting);
         ReactSession.set('user_id', this.state.googleId);
         ReactSession.set('is_authenticated', this.state.isLogined);
         this.props.login_func(propagated_state);
         this.props.load_backend_state();
     }
    }

    complete_oauth_login(data) {
        // pass google info to the backend, get a session token of our own
        const uri = `${config.urls.base_url}/auth/complete_oauth_login`
        const options = {
            method: 'POST',
            body: JSON.stringify(data)
        }
        fetch(uri, options)
            .then(response => response.json())
            .then(jsondata => {
                    // TODO compare, only set state if updated
                ReactSession.set('token', jsondata['kw_token']);
                fetch_with_token(`${config.urls.base_url}/status`);
                    });
    }
 
   logout (response) {
     this.setState(state => ({
       isLogined: false,
       accessToken: '',
       tokenId: '',
       googleId: '',
       tokenObj: null,
       message: '',
       codeUrl: ''
     }));
     for(const element of ['token', 'user_id', 'user_name',]) {
         ReactSession.set(element, null);
     }
     this.props.logout_func();
   }
 
   handleLoginFailure (response) {
     log(response);
     alert('Failed to log in')
   }
 
   handleLogoutFailure (response) {
     alert('Failed to log out')
   }
 
   render() {
       return (
     <div>
       { this.state.isLogined ?
       <div>

         {/*  XXX need to fix spacing/layout here */}
         <GoogleLogout
           clientId={ CLIENT_ID }
           buttonText='Logout'
           onLogoutSuccess={ this.logout }
           onFailure={ this.handleLogoutFailure }
         >
         </GoogleLogout>
         {/*<p>Hello { this.state.message} !</p>*/}
           {/*<OAuthEcobee user_id={this.state.googleId}/>*/}
           {/*<OAuthNest user_id={this.state.googleId}/>*/}
           {/*<DeviceList user_id={this.state.googleId}/>*/}
       </div> : <GoogleLogin
           clientId={ CLIENT_ID }
           buttonText='Login'
           onSuccess={ this.login }
           onFailure={ this.handleLoginFailure }
           cookiePolicy={ 'single_host_origin' }
           responseType='code,token'
         />
       }
       {/* this.state.tokenId ? <h5>Your Access Token: <br/><br/> { this.state.tokenId }</h5> : null */}
 
     </div>
     )
   }
 }

 export default GoogleBtn;