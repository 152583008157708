import React, {Component} from 'react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
// import OauthPopup from 'react-oauth-popup';
import config from '../config.js';
import OauthPopup from "./OAuthPopup";
import {fetch_with_token, log} from "./util";

class OAuthNest extends Component {
    constructor(props) {
        super(props);
        const baseURI = 'https://nestservices.google.com/partnerconnections';
        const params = {
            redirect_uri: config.urls.oauth_redirect_url,
            access_type: "offline",
            prompt: "consent",
            client_id: config.nest.client_id,
            response_type: "code",
            scope: "https://www.googleapis.com/auth/sdm.service",
            state: JSON.stringify({
                user_id: this.props.login.user_id,
                provider: "nest",
            })
        };
        const queryString = new URLSearchParams(params)
        this.OAuthURL = `${baseURI}/${config.nest.project_id}/auth?${queryString.toString()}`;

        if(config.MOCK_OAUTH){
            let qs = new URLSearchParams({state: params.state, code: "fake_code"});
            this.OAuthURL = `${config.urls.oauth_redirect_url}?state=${params.state}&code=fake_code`;
        }
        log('OAUTH url is ' + this.OAuthURL);
        this.deauthorize=this.deauthorize.bind(this);


    }

    async onCode(code, params){
        console.log('onCode')
        console.log(code, params)
    }

    onClose(){
        console.log('onClose');
    };

    async deauthorize() {
        // TODO: user_id in props, make this POST
        var uri = `${config.urls.user_state}?user_id=${this.props.login.user_id}&provider=nest&action=deauthorize`
        fetch_with_token(uri)
            .then(result => this.props.load_backend_state());
    }

    render() {
        return (
            <div>
            {this.props.user_state.credentials && this.props.user_state.credentials.nest ?
                    <Button onClick={this.deauthorize}>Deauthorize Nest</Button>
                    :
            <OauthPopup
                url={this.OAuthURL}
                onCode={this.onCode}
                onClose={this.onClose}
            >
                <Button>Authorize Nest</Button>
            </OauthPopup>}
                </div>
        )
    }
}

export default OAuthNest;