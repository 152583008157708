import React, { Component, PureComponent, ReactChild } from 'react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import OauthPopup from 'react-oauth-popup';
import config from "../config.js";
import {fetch_with_token, log} from "./util";

class OAuthEcobee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            "is_authorized": false,
        };
        // const clientId = "4McV4q8LNcmjEDYNs3TX2FwR23NWN8Kz";
        const scope = "smartWrite";
        const baseUrl = "https://api.ecobee.com/authorize?";
        log('user login state is ' + this.login);
        const oauth_state = JSON.stringify({
            user_id: this.props.login.user_id || 'unnamed_user',
            provider: "ecobee",
        })

        this.authURL = baseUrl.concat(
            "response_type=code", "&",
            "client_id=", config.ecobee_client_id, "&",
            "redirect_uri=", config.urls.oauth_redirect_url, "&",
            "scope=", scope, '&',
            "state=", oauth_state);
        log('ecobee url is ' + this.authURL);
        this.deauthorize = this.deauthorize.bind(this);
    }

    async onCode(code, params) {
    }

    onClose() {
        console.log("closed!")
    };

    async handleClick() {
    }

    async deauthorize() {
        // TODO: user_id in props, make this POST
        var uri = `${config.urls.user_state}?user_id=${this.props.user_id}&provider=ecobee&action=deauthorize`
        fetch_with_token(uri)
            .then(result => this.props.load_backend_state());
    }

    render() {
        return (
            <div>
                {this.props.user_state.credentials && this.props.user_state.credentials.ecobee ?
                    <Button onClick={this.deauthorize}>Deauthorize Ecobee</Button>
                    :
                    <OauthPopup
                    url={this.authURL}
                    onCode={this.onCode}
                    onClose={this.onClose}
                >
                    <Button>Authorize Ecobee</Button>
                </OauthPopup>
                }
            </div>
        );
    }
}

export default OAuthEcobee;