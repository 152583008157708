var ENV = process.env.NODE_ENV;
console.log('env is ' + ENV);
let domain = window.location.hostname;
let config = {
    urls: {
        "base_url": "http://127.0.0.1:5000/kw",
        // "user_state": "http://localhost:8083",
        // user_state: "https://us-central1-kilowatt-283818.cloudfunctions.net/user_state",
        user_state: "http://localhost:5000/kw/user_state",
        set_backend_state: "http://localhost:5000/kw/set_backend_state",


        "oauth_redirect_url": "http://localhost:5000/kw/get_authorization_code",
    },
    MOCK_OAUTH: false,
    ecobee_client_id: '3saIY5PmWRzuPPVyjlLdajloZtT02iAu',
    nest: {
        project_id: "35f230e0-d9b0-40a4-a4fa-37702fc741cd",
        // client_id: "712794027289-dke7sjgaocdk24da3q40pe93pus2b5nr.apps.googleusercontent.com"
        client_id: "1092077527454-nro33ulpr5bnee03253jakr7rgft9all.apps.googleusercontent.com",
    }
};

if (ENV !== 'development') {
    config['urls'] = {
        // user_state: "https://us-central1-kilowatt-283818.cloudfunctions.net/user_state",
        user_state: "https://" + domain + '/kw/user_state',
        oauth_redirect_url: 'https://' + domain + '/kw/get_authorization_code',
        set_backend_state: 'https://' + domain + '/kw/set_backend_state',

    };
    config['MOCK_OAUTH'] = false;
}


export default config;