import {Component} from "react";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import {Card, Modal, Table} from "react-bootstrap";

export default class DeviceInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
        this.hours = []
        for(let i = 0;i<=24;i++){
            this.hours.push(("00"+i).slice('-2') + ':00:00')
        }


        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
    }

    handleClose(){
        this.setState({show:false});
    }

    handleOpen(){
        this.setState({show:true});
    }

    render() {
        return (
            <>
            <Button variant="primary" onClick={this.handleOpen}>
                {this.props.thermostat_details.metadata.name}            </Button>
            <Modal size="xl" scrollable={true} show={this.state.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Thermostat Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Accordion>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    Device Info
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                    Readings
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    <Table>
                                        <thead>
                                        <tr>
                                        <td>Date</td>
                                        <td>Temperature</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.props.thermostat_details.readings.map(
                                            reading => {
                                                return <tr>
                                                    <td>{reading.reading.timestamp}</td>
                                                    <td>{Math.round(reading.reading.temperature_f*100) / 100} °F</td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>

                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                    Weather Forecasts
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                    <Table responsive>
                                        <thead>
                                        <tr>
                                            <td>Date</td>
                                            {this.hours.map(hour => {
                                                return                                             <td>{hour}</td>
                                            })}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.props.thermostat_details.weather_forecasts.map(
                                            forecast => {
                                                return <tr>
                                                    <td>{forecast.date}</td>
                                                    {this.hours.map(hour => {
                                                        return <td>
                                                            {forecast[hour] || ' - '}
                                                        </td>})
                                                    }

                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>

                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion></Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            </>
        )
    }
}