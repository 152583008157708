import {ReactSession} from "react-client-session";


export function log(msg) {
    // Sentry.captureMessage(msg);
    console.log(msg);
}

export function fetch_with_token(uri){
    return fetch(uri, {
        headers: {"Authorization": `Bearer ${ReactSession.get('token')}`}
    })
}