import React from 'react';
import logo from '../logo.svg';
import './App.css';

import GoogleBtn from './GoogleBtn';
import OAuthNest from "./OAuthNest";
import OAuthEcobee from "./OAuthEcobee";
import DeviceList from "./DeviceList";
import {DeviceArea} from "./DeviceArea";
import {ReactSession} from "react-client-session";
import LocationForm from "./LocationForm";



function App() {
    ReactSession.setStoreType("localStorage");


  return (
    <div className="App">
      <header className="App-header">
        <p>Kilowatt AI</p>
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          
        </p>
          <DeviceArea/>


      </header>
    </div>
  );
}

export default App;
