import {Component} from "react";
import config from "../config";
import {fetch_with_token} from "./util";
import {ReactSession} from "react-client-session";
import Button from "react-bootstrap/Button";
import {Modal} from "react-bootstrap";

export default class LocationForm extends Component {
    constructor(props) {
        super(props);
        this.zipURL  = `${config.urls.base_url}/zipcode`;
        this.state = {
            isDefined: false, // do we have ANY zipcode in the db?
            isEditable: false, // has user clicked edit
            isInFlight: true, // we've sent something to the backend, not got a reply yet
            zipcode: ''};
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
    }

    componentDidMount(){
        console.log('getting zip code')
        fetch_with_token(this.zipURL)
            .then(
            response => response.json())
            .then(jsondata =>
            this.setState({
                zipcode: jsondata['zipcode'],
                isInFlight: false
            }));
    }

    handleChange(event) {
        this.setState({zipcode: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({isInFlight: true});
        const options = {
            method: 'POST',
            body: JSON.stringify({zipcode: this.state.zipcode}),
            headers: {"Authorization": `Bearer ${ReactSession.get('token')}`}
        }
        fetch(this.zipURL, options)
            .then(response => {
                const js = response.json()
                this.setState({
                    isInFlight: false,
                    zipcode: js['zipcode']
                });
            })
    }

    handleClose(){
        this.setState({show:false});
    }

    handleOpen(){
        this.setState({show:true});
    }

    render() {
        return (
            <>
                <Button variant="primary" onClick={this.handleOpen}>
                Settings
            </Button>
        <Modal show={this.state.show}
        onHide={this.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <form onSubmit={this.handleSubmit}><label>
                Zip code
                <input type="text" value={this.state.zipcode} onChange={this.handleChange}/> </label>
                <input type="submit" disabled={this.state.isInFlight} value="Update"/>
            </form>
            </Modal.Body>
        </Modal>
                </>
        );
    }
}
