import React, { Component, PureComponent, ReactChild } from 'react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Table} from "react-bootstrap";

class DeviceList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            devices: []
        };
        this.toggleDebug = this.toggleDebug.bind(this);
    }


    toggleDebug() {
        this.context.debug = !this.context.debug
    };


    render() {
        return (
  <div>
      {this.props.user_state.devices_nest && this.props.user_state.devices_nest.length ?
      <div>
          <h2>Nest Devices</h2>

          <ul>
              {this.props.user_state.devices_nest.map(item => {
                  return <li key={item.name}>{item.name}</li>
              })
              }
          </ul>
      </div>
          :
          <span></span>}
      {/*<h3>Ecobee</h3>*/}
      {/*{this.props.user_state.devices_ecobee.length ?*/}
      {/*    <ul>*/}
      {/*        {this.props.user_state.devices_ecobee.map(item => {*/}
      {/*            return <li>{item.name}</li>*/}
      {/*        })*/}
      {/*        }*/}
      {/*    </ul>*/}
      {/*    : <span>No Devices</span>*/}
      {/*}*/}


      {/*      /!*<Table variant="dark" ><tbody>*!/*/}
{/*      /!*    {this.props.user_state.devices_nest && this.props.user_state.devices_nest.map(item =>{*!/*/}
{/*      /!*    return <tr><td>{item.name}</td><td><Button onClick={(e) =>this.deleteItem(item, e)}>Delete</Button></td></tr>*!/*/}
{/*      /!*})}*!/*/}
{/*      /!*</tbody>*!/*/}
{/*      /!*</Table>*!/*/}
{/*<label>*/}
{/*      <input type="checkbox"defaultChecked={this.context.debug} onChange={this.toggleDebug}/>*/}
{/*    Debug?*/}
{/*</label>*/}
  </div>
        )
    }

    deleteItem(item, event) {
    }
}

export default DeviceList;